<script>
  /**
   * An image slider with an inner or outer gallery.
   */
  export default {
    props: {
      /** Images used in the slider. */
      images: {
        type: Array,
        required: true,
      },
      /** Whether the slider has navigation (previous and next slide) buttons. */
      hasNavigation: {
        type: Boolean,
        default: false,
      },
      /** Whether the gallery is displayed within the main image or outside the image. */
      innerGallery: {
        type: Boolean,
        default: false,
      },
      /** Whether the gallery is displayed to the left of the main image (vs bottom, which is the default). */
      galleryOnLeft: {
        type: Boolean,
        default: false,
      },
      /** The images' alternative text. */
      alt: {
        type: String,
        required: true,
      },
      arrowBgColor: {
        type: String,
        default: 'dawn',
        validator: (value) => ['dawn', 'none'].includes(value),
      },
    },
    data() {
      return {
        imageActiveIndex: 0,
      }
    },
    computed: {
      arrowBgColorClass() {
        return this.arrowBgColor === 'dawn' ? 'arrow-bg-dawn' : ''
      },
    },
  }
</script>

<template>
  <div :class="`${galleryOnLeft ? 'flex flex-row-reverse' : ''}`">
    <BaseSlider
      :has-navigation="hasNavigation"
      :active-index="imageActiveIndex"
      :class="`slider ${arrowBgColorClass}`"
      @slideChange="imageActiveIndex = $event"
    >
      <BaseSliderSlide v-for="(imageUrl, imageIndex) in images" :key="imageIndex">
        <BaseImage
          :src="imageUrl"
          :alt="alt"
          responsive="lg"
          :sizes="{ md: '80vw', lg: '40vw' }"
          :data-cy="`main-image-${imageIndex + 1}`"
        />
      </BaseSliderSlide>
    </BaseSlider>
    <ul
      v-if="images.length > 1"
      role="radiogroup"
      aria-label="Image gallery"
      :class="
        innerGallery
          ? 'mx-6 mt-4 xs:mt-6 lg:mt-0 lg:z-10 lg:absolute lg:inset-x-0 lg:mx-10 lg:bottom-0 lg:mb-10 flex justify-center lg:justify-end'
          : galleryOnLeft
          ? 'flex flex-col min-w-34 mr-4'
          : 'grid grid-cols-3 mt-1'
      "
    >
      <li
        v-for="(imageUrl, imageIndex) in images"
        :key="imageIndex"
        :class="
          innerGallery
            ? `${imageIndex > 0 ? 'ml-2' : ''} w-12 xs:w-15`
            : galleryOnLeft
            ? 'mb-2'
            : 'p-1'
        "
      >
        <button
          type="button"
          role="radio"
          :aria-checked="imageIndex === imageActiveIndex ? 'true' : 'false'"
          :class="`block w-full border-2 ${
            imageIndex === imageActiveIndex ? 'border-primary' : 'hover:border-gray-active'
          }`"
          :data-cy="`clickable-image-${imageIndex + 1}`"
          @click="imageActiveIndex = imageIndex"
        >
          <BaseImage
            :src="imageUrl"
            :alt="`Image ${imageIndex + 1}`"
            :aspect-ratio="1"
            responsive="xs"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="postcss" scoped>
  /* Override BaseSlider's default CSS */
  .slider {
    & >>> {
      .swiper-button-prev,
      .swiper-button-next {
        @apply border-1 border-black -mt-5;

        @screen md {
          @apply -mt-7;
        }
      }

      .swiper-button-prev {
        @apply left-0 ml-2;
      }

      .swiper-button-next {
        @apply right-0 mr-2;
      }
    }

    &.arrow-bg-dawn >>> {
      .swiper-button-prev,
      .swiper-button-next {
        @apply bg-dawn;
      }
    }
  }
</style>
