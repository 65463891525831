var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("" + (_vm.galleryOnLeft ? 'flex flex-row-reverse' : ''))},[_c('BaseSlider',{class:("slider " + _vm.arrowBgColorClass),attrs:{"has-navigation":_vm.hasNavigation,"active-index":_vm.imageActiveIndex},on:{"slideChange":function($event){_vm.imageActiveIndex = $event}}},_vm._l((_vm.images),function(imageUrl,imageIndex){return _c('BaseSliderSlide',{key:imageIndex},[_c('BaseImage',{attrs:{"src":imageUrl,"alt":_vm.alt,"responsive":"lg","sizes":{ md: '80vw', lg: '40vw' },"data-cy":("main-image-" + (imageIndex + 1))}})],1)}),1),_vm._v(" "),(_vm.images.length > 1)?_c('ul',{class:_vm.innerGallery
        ? 'mx-6 mt-4 xs:mt-6 lg:mt-0 lg:z-10 lg:absolute lg:inset-x-0 lg:mx-10 lg:bottom-0 lg:mb-10 flex justify-center lg:justify-end'
        : _vm.galleryOnLeft
        ? 'flex flex-col min-w-34 mr-4'
        : 'grid grid-cols-3 mt-1',attrs:{"role":"radiogroup","aria-label":"Image gallery"}},_vm._l((_vm.images),function(imageUrl,imageIndex){return _c('li',{key:imageIndex,class:_vm.innerGallery
          ? ((imageIndex > 0 ? 'ml-2' : '') + " w-12 xs:w-15")
          : _vm.galleryOnLeft
          ? 'mb-2'
          : 'p-1'},[_c('button',{class:("block w-full border-2 " + (imageIndex === _vm.imageActiveIndex ? 'border-primary' : 'hover:border-gray-active')),attrs:{"type":"button","role":"radio","aria-checked":imageIndex === _vm.imageActiveIndex ? 'true' : 'false',"data-cy":("clickable-image-" + (imageIndex + 1))},on:{"click":function($event){_vm.imageActiveIndex = imageIndex}}},[_c('BaseImage',{attrs:{"src":imageUrl,"alt":("Image " + (imageIndex + 1)),"aspect-ratio":1,"responsive":"xs"}})],1)])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }